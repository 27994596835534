<template>
  <div >
<b-row>
  <b-col>
    <b-card
        no-body

        :class="educationType === 2 ? 'card-statistics border-success' :'card-statistics' " >
      <b-card-header >
        <b-card-title >
        博士一等奖
        </b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <b-button
              variant="outline-success"
              pill
              @click="changeEducationType(2)"
          >
            博士评选
          </b-button>
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col >
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="success"
                >
                  <feather-icon
                      size="24"
                      icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ awards.doctor_number }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  人数
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="info"
                >
                  <feather-icon
                      size="24"
                      icon="UserCheckIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ awards.doctor_quota}}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  名额
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col>
            <b-media no-body>
              <b-media-aside
                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="danger"
                >
                  <feather-icon
                      size="24"
                      icon="ThumbsUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{DoctorSurplus }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  票数
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>


      </b-card-body>
    </b-card>
  </b-col>
  <b-col >
      <b-card
          no-body
          :class="educationType === 1 ? 'card-statistics border-success' :'card-statistics' "
      >
        <b-card-header >
          <b-card-title >

         硕士一等奖
          </b-card-title>
          <b-card-text class="font-small-2 mr-25 mb-0">
            <b-button
                variant="outline-success"
                pill
                @click="changeEducationType(1)"
            >
              硕士评选
            </b-button>
          </b-card-text>
        </b-card-header>
        <b-card-body class="statistics-body">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside
                    class="mr-2"
                >
                  <b-avatar
                      size="48"
                      variant="success"
                  >
                    <feather-icon
                        size="24"
                        icon="UsersIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{awards.master_number }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    人数
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col>
              <b-media no-body>
                <b-media-aside
                    class="mr-2"
                >
                  <b-avatar
                      size="48"
                      variant="info"
                  >
                    <feather-icon
                        size="24"
                        icon="UserCheckIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ awards.master_quota }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    名额
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col>
              <b-media no-body>
                <b-media-aside
                    class="mr-2"
                >
                  <b-avatar
                      size="48"
                      variant="danger"
                  >
                    <feather-icon
                        size="24"
                        icon="ThumbsUpIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{MasterSurplus }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    票数
                  </b-card-text>
                </b-media-body>
              </b-media>

            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
  </b-col>
   </b-row>

    <b-card
        no-body
        class="mb-0"
    >
      <b-card-header class="p-1">
      <b-card-title class="font-medium-2">
        <feather-icon
            icon="LockIcon"
            size="18"
        />
        <span class="align-middle ml-50">投票列表</span>
      </b-card-title>
    </b-card-header>

            <b-table
                striped
                hover
                small
                sticky-header
                :items="items"
                :fields="fields"
                style="min-height:700px"
            >
              <template #cell(achievement)="data">
                <div  v-if="data.value.length > 0">
                  <div v-for="(v,i) in JSON.parse(data.value)" >
                    <b-badge variant="success" v-if="v.author_sort === 1 || v.author_sort === 2 ">
                      {{ v.journal_name }}
                    </b-badge>
                  </div>
                </div>
              </template>

              <template #cell(student_name)="row">
                {{row.item.student_name+' ('+GetToSex(row.item.sex)+')' }}

              </template>

              <template #cell(pdf_url)="row">
                <b-button
                    variant="outline-success"
                    v-b-toggle.showRequestView
                    @click="show_request(row.item.img_url,row.item.request_id)"
                >
                  材料
                </b-button>
              </template>

              <template #cell(education_status_name)="data">
                <b-badge
                    pill
                    :variant="data.value.variant"
                >
                  {{ data.value.value }}
                </b-badge>
              </template>
              <template #cell(whether_get)="data">
                <b-badge
                    pill
                    :variant="data.value.variant"
                >
                  {{ data.value.value }}
                </b-badge>
              </template>

              <template #cell(actions)="row">
                <b-row>
                  <b-col cols="8" >
                      <b-button
                          v-if=" voteLists.includes(row.item.id) === false"
                          variant="outline-primary"
                          @click="addVote(row.item.id,row.index)"
                      >
                        投票
                      </b-button>
                    <b-button
                        v-if="voteLists.includes(row.item.id)"
                        variant="outline-danger"
                        @click="delVote(row.item.id,row.index)"
                    >
                      取消投票
                    </b-button>
                  </b-col>
<!--                  <b-col cols="8" v-if="awards.calculation_type === 1">-->

<!--                    <b-form-input-->
<!--                        v-if=" scoreLists.find(item => item.id === row.item.id) === undefined"-->
<!--                        number-->
<!--                        trim-->
<!--                        v-model="row.item.score_zero"-->
<!--                        @blur="addScore(row.item.id,row.index)"-->
<!--                        max=100-->
<!--                        min=0-->
<!--                    />-->
<!--                    <b-form-input-->
<!--                        v-if=" scoreLists.find(item => item.id === row.item.id) !== undefined "-->
<!--                        number-->
<!--                        trim-->
<!--                        v-model="scoreLists.find(item => item.id === row.item.id).score"-->
<!--                        @blur="addScore(row.item.id,row.index)"-->
<!--                        max=100-->
<!--                        min=0-->
<!--                    />-->

<!--                  </b-col>-->
                </b-row>
              </template>
            </b-table>

<b-row>
          <b-col

              offset-md="5"
          >
            <b-form-group

                label-for="search"
            >
              <b-button
                  variant="primary"
                  class="mr-2"
                  type="button"
                  @click="saveAppraise"
                  v-if="showSave"
              >确认提交</b-button>



            </b-form-group>
          </b-col>
      </b-row>

    </b-card>


    <b-sidebar
        v-model="showStudentRequest"
        width="900px"
        hide-footer
        lazy
    >
      <b-card>
        <img-lists   :img-url="img_url" :request_id="request_id"></img-lists>
      </b-card>

    </b-sidebar>

  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BTable, VBToggle, BButton, BBadge, BModal, BCardBody, BImg, VBTooltip, BAlert, BDropdown, BDropdownItem,
  BFormGroup,BCardHeader,BCardTitle,BMediaBody,BMedia,BMediaAside,BAvatar,BCardText,BSidebar,BFormInput
} from 'bootstrap-vue'
import 'animate.css'

import ImgLists from "@/views/awards/ImgLists.vue";

export default {
  name: "appraise",
  components: {
    BAvatar,
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BModal,
    BCardBody,
    BImg,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BCardText,
    ImgLists,
    BFormInput,
    BSidebar,
    BCardHeader,BCardTitle,
    BMediaBody,BMedia,BMediaAside

  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      educationType:2, //1 硕士  2 博士
      awards_id:Number(this.$route.query.id),
      calculation_type:0,
      pdf_url:"",
      confirm:0,
      request_id:0,
      fields:[],
      fields1: [
        {key: 'grade', label: '年级',},
        //{key: 'student_number', label: '学号',},
        {key: 'student_name', label: '姓名',},
        // {key: 'sex', label: '性别',formatter: value => this.GetSex(value),},
        {key: 'speciality_name', label: '专业',},
        // {key: 'education_status_name', label: '培养层次', formatter: value => this.StudentCultivateColor(value),},
        {key: 'study_category_name', label: '攻读方式',},
        {key: 'achievement', label: '科研成果',},
        {key:"pdf_url",label: "调出材料"},
        //{key: 'whether_get', label: '曾经何时获得',formatter: value => this.StudentWhetherGetColor(value),},
        { key: 'actions', label: '操作' },
      ],
      fields2: [
        {key: 'grade', label: '年级',},
        //{key: 'student_number', label: '学号',},
        {key: 'student_name', label: '姓名',},
        // {key: 'sex', label: '性别',formatter: value => this.GetSex(value),},
        {key: 'speciality_name', label: '专业',},
        // {key: 'education_status_name', label: '培养层次', formatter: value => this.StudentCultivateColor(value),},
        {key: 'study_category_name', label: '攻读方式',},
        {key: 'achievement', label: '科研成果',},
        {key:"pdf_url",label: "调出材料"},
        {key: 'score', label: '初评分数'},
        {key: 'actions', label: '操作' },
      ],
      items: [],
      DoctorSurplus:0,
      MasterSurplus:0,
      timer:null,
      doctorNumber:0,
      masterNumber:0,
      awards: {
        quota_type:1,
        affirm_status:0,
        round:0,
        doctor_quota: 0,
        master_quota: 0,
        doctor_number:0,
        master_number:0,
      },

      voteLists:[],
      scoreLists:[],
      showSave:true,
      showViewData:{},
      showStudentRequest:false,
      img_url:"",

    }
  },

  created() {
    console.log(this.$route.query.id)
    this.getAwards()
    this.setTimes()

    // if (this.isMobile()){
    //
    //   this.showToast(400,"在渲染过程中涉及到与其他软件进行抢占硬件资源,设备有可能出现卡顿,请耐心等待")
    //   this.showToast(400,"系统监测到当前使用低性能的移动设备,正在自动启用多线程技术加速PDF文件渲染")
    //   //this.$store.state.appConfig.layout.type = "horizontal"
    // }

  },

  destroyed() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer)
    this.timer = null
    console.log('清除定时器')
  },

  watch:{
      'awards.round':{
        handler(newVal,oldVal){
          if (newVal !== oldVal){
            console.log(this.awards)
            console.log(this.awards.affirm_status)
            if (this.awards.affirm_status === 0){
              this.showToast(200,'进入第 '+newVal+" 轮投票")
              this.getAwardsAppraiseLists()
                this.DoctorSurplus = this.awards.doctor_quota
                this.MasterSurplus = this.awards.master_quota
                this.voteLists = []
                this.showSave = true
            }

          }
        }
      },
    'awards.affirm_status':{
        handler(newVal,oldVal){
        if (newVal === 1){
          this.showToast(200,'准备进入确认轮投票')
          clearInterval(this.timer)
          this.timer = null
          this.$router.push({ path: '/awards-vote', query: { awards_id: this.awards.id ,quota_type: this.awards.quota_type} })
          //this.getAwardsAppraiseLists()
        }
    }},


  },

  methods:{


    GetToSex(val){
      return this.GetSex(val)
    },

  changeEducationType(type){
    this.educationType = type
    this.getAwardsAppraiseLists()
  },

    getAwardsAppraiseLists(){
      this.AwardsStudentLists({awards_id:this.awards_id,round:this.awards.round,education_type:this.educationType,calculation_type:this.calculation_type}).then(res =>{
        this.items = res.data.data
            if (this.educationType === 1){
              if (this.MasterSurplus !== 0){
                this.MasterSurplus = this.MasterSurplus - res.data.count
              }
            }else{
              if (this.DoctorSurplus !== 0) {
                this.DoctorSurplus = this.DoctorSurplus - res.data.count
              }
            }


      })
    },

    getAwards(){
      this.AwardsGet({id:this.awards_id}).then(res =>{
        this.awards = res.data.data
        if (this.awards.quota_type === 0) {
          console.log(this.awards)
          this.awards.doctor_quota = this.awards.doctor_quota_one
          this.awards.master_quota = this.awards.master_quota_one
        }else{
          this.awards.doctor_quota = this.awards.doctor_quota
          this.awards.master_quota = this.awards.master_quota
        }
        this.calculation_type = this.awards.calculation_type
        if (this.awards.calculation_type === 0){
          this.fields = this.fields1
        }else{
          this.fields = this.fields2
        }

      })
    },




    setTimes() {
      if (this.timer === null) {
        this.timer = setInterval(() => {
          console.log('定时器运行')
          this.getAwards()
        }, 10000)
      }
    },



    addVote(id,index){
      let education = this.items[index].education
      console.log(education)
      if (education === 2){
        if ( this.DoctorSurplus  > 0 ){
          this.DoctorSurplus = this.DoctorSurplus - 1
        }else{
          this.showToast(400,'已无博士投票名额')
          return false
        }
      }else{
        if (this.MasterSurplus > 0 ) {
          this.MasterSurplus = this.MasterSurplus - 1
        }else{
          this.showToast(400,'已无硕士投票名额')
          return false
        }
      }
      this.voteLists.push(id)
      this.items[index].vote = 1
    },

    delVote(id,index){
      let education = this.items[index].education
      if (education === 2){
          this.DoctorSurplus = this.DoctorSurplus + 1
      }else{
          this.MasterSurplus = this.MasterSurplus + 1
      }
      this.voteLists = this.voteLists.filter(item => item !== id)
      this.items[index].vote = 0
    },

    saveAppraise(){
      console.log(this.MasterSurplus)
      console.log(this.awards.master_quota)
        if(this.MasterSurplus === this.awards.master_quota && this.MasterSurplus !== 0){
          this.showToast(400,"硕士评选还可以继续投票")
          return false
        }
        if (this.DoctorSurplus === this.awards.doctor_quota && this.DoctorSurplus !== 0) {
          this.showToast(400, "博士评选还可以继续投票")
          return false
      }

      this.awardsAppraise({"awards_id":this.awards.id,"round":this.awards.round,"data":this.voteLists,"score":this.scoreLists}).then(res =>{
        this.showToast(res.data.code,res.data.msg)
        if (res.data.code === 200){
          this.showSave = false
          this.$swal({
            title: '操作成功',
            icon: 'success',
            text: res.data.msg +"(此消息将会在5秒内自动关闭)",
            timer: 5000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            showClass: {
              popup: 'animate__animated animate__flipInX',
            },
            buttonsStyling: false,
          })
        }
      })
    },

    show_request(url,id){

      this.img_url =  url
      this.request_id = id
      this.showStudentRequest = true
    },



  },
}
</script>

